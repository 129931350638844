<template>
    <div class="wheel-container">
      <canvas id="canvas" width="500" height="500"></canvas>
      <button @click="spinWheel" class="spin-button">
        หมุนวงล้อ
      </button>
    </div>
  </template>
  
  <script>
  import Winwheel from "winwheel";
  import { TweenMax } from "gsap";
  
  window.TweenMax = TweenMax;
  
  export default {
    data() {
      return {
        wheel: null,
      };
    },
    mounted() {
      this.initializeWheel();
    },
    methods: {
      initializeWheel() {
        this.wheel = new Winwheel({
          numSegments: 8, // จำนวนส่วนของวงล้อ
          segments: [
            { fillStyle: "#f9c802", text: "$5", textFontSize: 16 },
            { fillStyle: "#f2a602", text: "20$", textFontSize: 16 },
            { fillStyle: "#e6471d", text: "JACKPOT", textFontSize: 16, textFillStyle: "#ffffff" },
            { fillStyle: "#f2a602", text: "$15", textFontSize: 16 },
            { fillStyle: "#f9c802", text: "100$", textFontSize: 16 },
            { fillStyle: "#f9c802", text: "ZERO", textFontSize: 16 },
            { fillStyle: "#f2a602", text: "$50", textFontSize: 16 },
            { fillStyle: "#e6471d", text: "500$", textFontSize: 16 },
          ],
          animation: {
            type: "spinToStop",
            duration: 5, // ระยะเวลาการหมุน
            spins: 8, // จำนวนรอบที่จะหมุน
            callbackFinished: this.alertPrize,
            easing: "Power4.easeOut", // เอฟเฟกต์การหมุน
          },
          outerRadius: 200, // ขนาดวงล้อ
          textAlignment: "outer", // ตำแหน่งข้อความด้านนอก
          strokeStyle: "#ffffff", // สีขอบวงล้อ
        });
      },
      spinWheel() {
        this.wheel.startAnimation();
      },
      alertPrize(indicatedSegment) {
        alert("คุณได้รับรางวัล: " + indicatedSegment.text);
      },
    },
  };
  </script>
  
  <style scoped>
  .wheel-container {
    text-align: center;
    position: relative;
  }
  
  canvas {
    margin: 0 auto;
    display: block;
    background-color: #f9f3d2; /* สีพื้นหลังของวงล้อ */
    border-radius: 50%;
    box-shadow: 0 0 20px rgba(255, 215, 0, 0.7);
  }
  
  .spin-button {
    margin-top: 20px;
    background-color: #ffcc00;
    color: #000;
    padding: 10px 20px;
    font-size: 18px;
    font-weight: bold;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .spin-button:hover {
    background-color: #ffdd33;
  }
  </style>
  