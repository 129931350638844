<!-- <template>
  <div class="grid grid-cols-4 gap-2 p-2 max-w-full md:max-w-[670px] mx-auto">
    <div
      v-for="(item, index) in menuItems"
      :key="index"
      class="flex flex-col items-center bg-gradient-to-br from-blue-700 to-blue-500 rounded-lg shadow p-0"
    >
      <router-link :to="item.route" class="flex flex-col items-center">
        <div class="relative">
          <img :src="item.icon" alt="icon" class="w-12 h-12 mb-2 p-1" />
          <span
            v-if="item.badge"
            class="absolute top-0 right-0  text-white text-xs font-bold rounded px-2 py-0.5"
          >
            {{ item.badge }}
          </span>
        </div>
        <span class="text-center text-white font-medium text-sm mb-2">
          {{ item.label }}
        </span>
      </router-link>
    </div>
  </div>
</template> -->


<template>
  <div class="grid grid-cols-4 gap-2 p-2 max-w-full md:max-w-[670px] mx-auto">
    <div v-for="(item, index) in menuItems" :key="index"
      class="flex flex-col items-center bg-gradient-to-br from-blue-700 to-blue-500 rounded-lg shadow p-0">
      <router-link to="#" @click.prevent="showToast" class="flex flex-col items-center">
        <div class="relative">
          <img :src="item.icon" alt="icon" class="w-12 h-12 mb-2 p-1" />
          <!-- Badge สำหรับบางรายการ -->
          <span v-if="item.badge" class="absolute top-0 right-0 text-white text-xs font-bold rounded px-2 py-0.5">
            {{ item.badge }}
          </span>
        </div>
        <span class="text-center text-white font-medium text-sm mb-2">
          {{ item.label }}
        </span>
      </router-link>
    </div>
  </div>
</template>


<script>


export default {
  name: "MenuList",
  data() {
    return {
      menuItems: [
        { icon: require("@/assets/icons/lottery.png"), label: "ทายหวย", route: "/lottery" },
        { icon: require("@/assets/icons/football-ball.png"), label: "ทายบอล", route: "/football" },
        { icon: require("@/assets/icons/boxing-gloves.png"), label: "ทายมวย", route: "/boxing" },
        { icon: require("@/assets/icons/jackpot.png"), label: "สุ่มสล็อต", route: "/slots" },
        { icon: require("@/assets/icons/baccarat.png"), label: "สุ่มบาคาร่า", route: "/baccarat" },
        { icon: require("@/assets/icons/birthday.png"), label: "โปรวันเกิด", route: "/birthday" },
        { icon: require("@/assets/icons/gold.png"), label: "รับทองคำ", route: "/gold" },
        { icon: require("@/assets/icons/employee.png"), label: "เช็กชื่อ", route: "/checkin" },
      ],
    };
  },
  methods: {
    showToast() {
  this.$toast.open({
    message: `
      กิจกรรมยังไม่เริ่ม ลูกค้ารอเล่นได้เลยนะคะ เร็วๆ นี้
    `,
    type: "info",
    position: "top-right",
    duration: 5000,
    style: {
      backgroundColor: '#ffc107',
      color: 'black'
    }
  });
},
  },
};
</script>

<style scoped>
.bg-gradient-to-br {
  background: linear-gradient(135deg, #172f82, #180650);
  border: 1px solid #EECE61;
}
</style>
