<template>
    <div class=" pb-16 overflow-y-auto md:max-w-[670px] mx-auto pt-16">
      <HeaderLogin />
      <BtnBack /> 

      <h1 class="text-2xl text-white font-bold text-center mb-4">ประวัติทายหวย</h1>
  
      <!-- ประวัติการทายหวย -->
      <div class="overflow-x-auto p-4">
        <table class="w-full bg-[#171e79] text-white p-4 rounded-lg table-auto border-collapse">
          <thead class="bg-yellow-400 text-black">
            <tr>
              <th class="px-4 py-2 text-left">เลขทายหวย</th>
              <th class="px-4 py-2 text-left">สถานะ</th>
              <th class="px-4 py-2 text-left">เพิ่มเครดิต</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(history, index) in historyList" :key="index" class="border-b border-yellow-500">
              <td class="px-4 py-2">{{ history.number }}</td>
              <td :class="{ 'text-green-500': history.status === 'ถูก', 'text-red-500': history.status === 'ไม่ถูก' }" class="px-4 py-2">
                {{ history.status }}
              </td>
              <td class="px-4 py-2">{{ history.credit }} บาท</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
</template>

<script>
import HeaderLogin from "@/components/HeaderLogin.vue";
import BtnBack from "@/components/BtnBack.vue";


export default {
  name: "LotteryHistory",
  components: {
    HeaderLogin,
    BtnBack,
  },
  data() {
    return {
      historyList: [
        { number: "86", status: "ถูก", credit: 1000 },
        { number: "21", status: "ไม่ถูก", credit: 0 },
        { number: "90", status: "ถูก", credit: 500 },
        { number: "78", status: "ไม่ถูก", credit: 0 },
      ],
    };
  },
};
</script>

<style scoped>
.material-icons {
  font-size: 24px;
}

/* เพิ่มสไตล์สำหรับตาราง */
table {
  border-spacing: 0;
}

th, td {
  border-bottom: 1px solid #ffdd57;
}

th {
  font-weight: bold;
  text-transform: uppercase;
  background-color: #ffdd57;
}

td {
  text-align: left;
  padding: 12px 16px;
}

tr:hover {
  background-color: #1e2a7a;
}
</style>
