<template>
  <div class=" pb-16 overflow-y-auto md:max-w-[670px] mx-auto pt-16 p-4">

    <HeaderLogin />
      <BtnBack/>

   

    <h2 class="text-yellow-400 text-2xl font-bold mb-4">กิจจะทำโปรวันเกิด รับเครดิดเพิ่ม</h2>

    <!-- โปรโมชันวันเกิด -->
    <div class="bg-[#fff5e4] text-[#815b2a] p-4 rounded-lg shadow-lg mb-6">
      <h3 class="text-center text-lg font-bold">
        โปรโมชั่น วันเกิด รับเพิ่ม 300 บาททันที
      </h3>
      <img
        src="@/assets/birthday-promo.png"
        alt="โปรวันเกิด"
        class="w-full h-auto my-4"
      />

      <p class="font-bold mb-2">เงื่อนไขการเข้าร่วมกิจกรรม</p>
      <ul class="list-disc ml-6 text-sm">
        <li>ต้องอยู่ในระบบอย่างต่ำ 10 วันรับสิทธิ์</li>
        <li>
          ถ่ายรูปคู่บัตรประชาชนพร้อมโชว์เลขมุมด้านซ้าย และมองเห็นชื่ออย่างชัดเจน
        </li>
        <li>ฝาก 100 บาทขึ้นไป</li>
      </ul>
    </div>

    <!-- ปุ่มแจ้งวันเกิด -->
    <div class="flex justify-center items-center ">
        <button
    v-if="!showUpload"
    @click="handleNotify"
    class="bg-yellow-400 px-4 py-2 rounded-lg text-black font-bold text-lg flex items-center justify-center zoom-animation"
  >
    <img src="@/assets/icons/birthday-cake.png" alt="Birthday Icon" class="w-6 h-6 mr-2" />
    แจ้งวันเกิด
  </button>

      <!-- ส่วนสำหรับอัปโหลดไฟล์ -->
      <div v-if="showUpload" class="mt-6">
        <label class="block mb-2 text-sm font-bold text-white"
          >อัปโหลดไฟล์บัตรประชาชน</label
        >
        <input
          type="file"
          @change="handleFileUpload"
          class="block w-full text-sm text-gray-500 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none"
        />

        <!-- ปุ่มสำหรับยืนยันการอัปโหลดไฟล์ -->
        <button
          @click="confirmUpload"
          class="bg-yellow-400 px-4 py-2 rounded-lg text-black font-bold text-lg mt-4"
        >
          อัปโหลด
        </button>

        <!-- แสดงสถานะการแจ้ง -->
        <div class="mt-4">
          <p class="text-white font-bold">
            สถานะ: <span class="text-yellow-400">รอคอนเฟิร์ม</span>
          </p>
        </div>
      </div>
    </div>

    <!-- ตารางแสดงชื่อยูสเซอร์ สถานะ เพิ่มเครดิต -->
    <div class="overflow-x-auto mt-10">
      <table
        class="w-full bg-[#171e79] text-white p-4 rounded-lg table-auto border-collapse"
      >
        <thead class="bg-yellow-400 text-black">
          <tr>
            <th class="px-4 py-2 text-left">ชื่อยูสเซอร์</th>
            <th class="px-4 py-2 text-left">สถานะ</th>
            <th class="px-4 py-2 text-left">เพิ่มเครดิต</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(history, index) in historyList"
            :key="index"
            class="border-b border-yellow-500"
          >
            <td class="px-4 py-2">{{ history.username }}</td>
            <td
              :class="{
                'text-green-500': history.status === 'สำเร็จ',
                'text-red-500': history.status === 'รอคอนเฟิร์ม',
              }"
              class="px-4 py-2"
            >
              {{ history.status }}
            </td>
            <td class="px-4 py-2">{{ history.credit }} บาท</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
  
  <script>
import BtnBack from "@/components/BtnBack.vue";
import HeaderLogin from "@/components/HeaderLogin.vue";

export default {
  components: {
    HeaderLogin,
    BtnBack
  },
  data() {
    return {
      showUpload: false, // กำหนดสถานะการแสดงส่วนอัปโหลดไฟล์
      historyList: [
        { username: "User123", status: "สำเร็จ", credit: 300 },
        { username: "User456", status: "รอคอนเฟิร์ม", credit: 0 },
        { username: "User789", status: "ไม่สำเร็จ", credit: 0 },
      ], // ตัวอย่างข้อมูลในตาราง
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    handleNotify() {
      this.showUpload = true; // เมื่อกดปุ่ม แจ้งวันเกิด จะแสดงส่วนอัปโหลดไฟล์
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      // ที่นี่คุณสามารถเพิ่มโค้ดสำหรับการอัปโหลดไฟล์ได้ตามความต้องการของระบบ
      console.log("Uploaded file:", file);
    },
    confirmUpload() {
      // ฟังก์ชันสำหรับยืนยันการอัปโหลดไฟล์
      console.log("File uploaded successfully");
    },
  },
};
</script>
  
  <style scoped>


h2 {
  text-align: center;
}

img {
  border-radius: 8px;
}

input[type="file"] {
  padding: 0.5rem;
}

table {
  border-spacing: 0;
}

th,
td {
  border-bottom: 1px solid #ffdd57;
}

th {
  font-weight: bold;
  text-transform: uppercase;
  background-color: #ffdd57;
}

td {
  text-align: left;
  padding: 12px 16px;
}

tr:hover {
  background-color: #1e2a7a;
}

@keyframes zoomInOut {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.zoom-animation {
  animation: zoomInOut 2s ease-in-out infinite;
}

</style>
  