<template>
  <div class="main-container">
    <!-- Header -->
    <HeaderLogin class="header-container" />

    <Textarea />
    <MenuList />
    <router-view /> 
    <GameList />
  </div>
</template>

<script>
import HeaderLogin from "@/components/HeaderLogin.vue";
import Textarea from "@/components/Textarea.vue";
import MenuList from "@/components/MenuList.vue";
import GameList from "@/components/GameList.vue";

export default {
  name: "MainLayout",
  components: {
    HeaderLogin,
    Textarea,
    MenuList,
    GameList,
  },
};
</script>

<style scoped>
/* เพิ่ม padding-top ให้กับ container หลัก เพื่อเลี่ยงการทับซ้อน */
.main-container {
  padding-top: 72px; /* กำหนดขนาดเท่ากับความสูงของ header */
}

/* header fixed ให้คงอยู่ด้านบน */
.header-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 50;
}
</style>
