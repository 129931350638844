<template>
  <div
    class="min-h-screen flex items-center justify-center bg-[#e4e9f7] relative"
  >
    <!-- รูปพื้นหลัง -->
    <div class="absolute inset-0 z-0">
      <img
        src="@/assets/background/login-background.png"
        alt="Background Image"
        class="w-full h-full object-cover"
      />
    </div>

    <button
      @click="goBack"
      class="bg-yellow-400 text-black px-3 py-2 rounded-full absolute top-4 left-4 flex items-center"
    >
      <span class="material-icons">arrow_back</span>
    </button>

    <!-- Container สำหรับฟอร์มล็อกอิน -->
    <div
      class="bg-[#171e79] border border-[#EECE61] p-8 rounded-xl shadow-lg w-[360px] relative z-10"
    >
      <!-- Logo -->
      <div class="text-center mt-10 mb-8">
        <img src="@/assets/MR61.svg" alt="Logo" class="mx-auto" />
      </div>

      <!-- Input Email -->
      <div class="mb-4">
        <label for="email" class="block text-white mb-2">ยูสเซอร์ในเว็พ</label>
        <input
          id="email"
          type="email"
          v-model="email"
          class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
          placeholder="กรอกยูสเซอร์ในเว็พ Mrz"
        />
      </div>

      <!-- Input Password -->
      <div class="mb-4 relative">
        <label for="password" class="block text-white mb-2"
          >หมายเลขมือถือ ที่สมัคในเว็พ</label
        >
        <div class="relative">
          <input
            id="password"
            :type="showPassword ? 'text' : 'password'"
            v-model="password"
            class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
            placeholder="กรอกหมายเลขมือถือ"
          />
          <span
            @click="togglePasswordVisibility"
            class="absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer flex items-center"
          >
            <span class="material-icons">visibility</span>
          </span>
        </div>
      </div>

      <!-- แจ้งเตือน -->
      <div
        class="bg-[#fff4e5] text-[#805300] rounded-lg p-4 flex items-center justify-between mb-6 shadow-sm"
        v-if="alertVisible"
      >
        <div class="flex items-center">
          <span class="material-icons mr-2">error_outline</span>
          <p class="text-sm">
            ต้องเป็น ยูสเซอร์ และ หมายเลขมือถือ
            ที่สมัคในเว็พเท่านั้นถึงจะได้รับโปรโมชั่นจาก MARUAY61
          </p>
        </div>
        <button @click="closeAlert" class="focus:outline-none">
          <span class="material-icons">close</span>
        </button>
      </div>

      <!-- Login Button -->
      <button
        @click="handleLogin"
        class="bg-yellow-400 text-black px-4 py-3 w-full rounded-lg font-bold text-lg hover:bg-yellow-500 transition-colors"
      >
        เข้าสู่ระบบ
      </button>
    </div>
  </div>
</template>

<script>
// import axiosInstance from "../config/axios.ts";
// import { API_LOGIN } from "../config/apiPath";


export default {
  name: "Login",
  data() {
    return {
      email: "",
      password: "",
      showPassword: false,
      alertVisible: true, // สำหรับควบคุมการแสดงแจ้งเตือน
    };
  },
  methods: {
    async handleLogin() {
      // นำทางไปยังหน้า MainLayoutAfterlogin.vue โดยไม่ตรวจสอบข้อมูลที่กรอก
      this.$router.push({ name: "MainLayoutAfterlogin" });

      // หากยังต้องการให้แสดง log เกี่ยวกับข้อมูลที่กรอก
      console.log(`Email: ${this.email}, Password: ${this.password}`);
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    goBack() {
      this.$router.go(-1);
    },
    closeAlert() {
      this.alertVisible = false; // ปิดแจ้งเตือนเมื่อคลิกที่ปุ่มปิด
    },
  },
};995
</script>

<style scoped>
body {
  font-family: "Poppins", sans-serif;
}

.shadow-lg {
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
}

.rounded-xl {
  border-radius: 12px;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.object-cover {
  object-fit: cover;
}

.z-0 {
  z-index: 0;
}

.z-10 {
  z-index: 10;
}

.material-icons {
  font-size: 24px;
}
</style>
