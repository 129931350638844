<template>
  <header class="bg-custom-gradient p-3 flex items-center justify-between md:max-w-[670px] mx-auto fixed top-0 left-0 right-0 z-50">
    <div class="flex items-center space-x-4">
      <!-- Logo -->
      <img src="@/assets/MR61.svg" alt="Logo" class="h-8" />
    </div>

    <div class="flex items-center space-x-4">

      <div class="flex items-center justify-between bg-coin text-white px-1 py-1 rounded-full shadow-lg w-auto">
        <span class="text-lg font-bold ml-3">พ้อย:</span> 
        <span class="text-lg font-bold ml-1">{{ points }}</span> 
        <div class="gold-gradient w-8 h-8 flex items-center justify-center rounded-full ml-4">
          <span class="text-black font-bold text-sm">MR</span> 
        </div>
      </div>

      <button @click="goToProfile" class="bg-coin text-white p-3 rounded-full shadow-lg hover:bg-blue-700 transition-all duration-300 flex items-center justify-center">
        <span class="material-icons text-xl">account_circle</span> 
      </button>
    </div>
  </header>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      points: 64 // กำหนดจำนวนพ้อยใน script
    };
  },
  methods: {
    goToProfile() {
      this.$router.push({ name: "profile" }); // นำทางไปยังหน้า profile
    }
  }
};
</script>

<style scoped>
.material-icons {
  font-size: 24px;
}

.bg-custom-gradient {
  background: linear-gradient(135deg, #172f82, #180650);
}
.bg-coin{
  background: linear-gradient(135deg, #172f82, #180650);
  border: 1px solid #FFD700;
}
/* ไล่ระดับสีทองสำหรับวงกลม MR */
.gold-gradient {
  background: linear-gradient(135deg, #FFD700, #FFC700, #FFB700);
  border-top: 4px solid #ffd84c;
  border-left: 4px solid #ffd84c;
  border-right: 4px solid #d57e08;
  border-bottom: 4px solid #d57e08;
}

/* เพิ่มเงาให้กับปุ่ม */
.shadow-lg {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.06);
}

.hover\:bg-blue-700:hover {
  background-color: #1e3a8a; /* ปรับสีเมื่อ hover ที่ปุ่ม */
}

.transition-all {
  transition: all 0.3s ease;
}

.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}
</style>
